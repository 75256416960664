import { useLocation } from "@reach/router"
import React from "react"
import biLogo from "../../images/bi-logo.svg"
import {
  LegalContainer,
  LegalDesc,
  LegalImg,
} from "../HeaderComponents/HeaderStyle"

function Legal({ props, onlyCTA }) {
  const { Legal } = props
  const location = useLocation()
  const lang = location.pathname.includes("/en")

  return (
    <LegalContainer className="rounder-lg">
      {!onlyCTA && (
        <div className="d-flex align-items-center">
          <LegalImg src={biLogo} alt="biLogo" />
          <LegalDesc>
            {lang ? (
              <>
                {Legal.DescRight1}
                <br /> {Legal.DescRight2}
                <br /> {Legal.DescRight3}{" "}
                <a
                  href="https://www.bi.go.id/id/qrcode/validate.aspx?id=0001.001/0024&tipe=PTD"
                  target="_blank"
                  style={{ color: "#db64a0" }}
                >
                  {Legal.LicenseNumber}
                </a>
              </>
            ) : (
              <>
                {Legal.DescRight1}
                <br /> {Legal.DescRight2}{" "}
                <a
                  href="https://www.bi.go.id/id/qrcode/validate.aspx?id=0001.001/0024&tipe=PTD"
                  target="_blank"
                  style={{ color: "#db64a0" }}
                >
                  {Legal.LicenseNumber}
                </a>
              </>
            )}
          </LegalDesc>
        </div>
      )}
    </LegalContainer>
  )
}

export default Legal
