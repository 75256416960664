import React from "react"
import { Row } from "react-bootstrap"
import NavigationBar from "./NavigationBar"
import Legal from "../HomeComponents/Legal"
// import Calculator from "./Calculator"

// import {
//   StyledHeader,
//   HeaderContainer,
//   SalutationCol,
//   HeaderH2,
// } from "./HeaderStyle"


function HeaderNavbar({ props }) {
  return (
    <>
    </>
    // <StyledHeader style={{ minHeight: "700px" }}>
    //   <NavigationBar props={props} />
    //   {<HeaderContainer className="flex-grow-1">
    //     <Row
    //       className="d-flex"
    //       style={{
    //         marginRight: "-15px",
    //         marginLeft: "-15px",
    //         flexWrap: "wrap",
    //       }}
    //     >
    //       <SalutationCol>
    //         <HeaderH2>
    //           {props.Title}
    //         </HeaderH2>
    //       </SalutationCol>
    //       {/* <Calculator /> */}
    //     </Row>
    //     <Legal props={props} />
    //   </HeaderContainer>}
    // </StyledHeader>
  )
}

export default HeaderNavbar
