import React from "react"
import '../layout.css'
import styled from "styled-components"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import { CustomButton } from "../Buttons/parent"

export const TncReferral = ({ propsData, isEnglish, isSgReferral }) => {
  const { Title, Desc, Button } = propsData

  const TncLinkSg = isEnglish
    ? `/en/sg/referral/term-and-condition-referral-program`
    : `/sg/referral/term-and-condition-referral-program`
  const TncLinkId = isEnglish
    ? `/en/referral/term-and-condition-referral-program`
    : `/referral/term-and-condition-referral-program`

  const TncLink = isSgReferral ? TncLinkSg : TncLinkId
  return (
    <>
      <MainDiv>
        {/* <CircleYellow /> */}
        <MainContainer>
          <MainTitle>{Title}</MainTitle>
          <DescText>{Desc}</DescText>
          <Link to={TncLink}>
            <CustomButton padding="10px 35px">{Button}</CustomButton>
          </Link>
        </MainContainer>
      </MainDiv>
      <ShadowDiv />
    </>
  )
}

const MainDiv = styled.div`
  background-color: transparent;
  box-shadow: -1px -14px 16px -3px rgba(0, 0, 0, 0.04) inset;
  position: relative;
  z-index: 3;
  margin: 200px 0px 0px 0px;
`

const ShadowDiv = styled.div`
  height: 50px;
  background-color: white;
  position: relative;
  z-index: 2;
`

const MainContainer = styled(Container)`
  background-color: #ff7b5a;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.07),
    -1px -14px 16px -3px rgba(0, 0, 0, 0.04) inset;
  border-radius: 15px 15px 0px 0px;
  padding: 80px 25px 100px 25px;
  text-align: center;
`

const MainTitle = styled.h2`
  font-family: "Typefez-black";
  font-size: 50px;
  color: white;
  margin-bottom: 10px;
`
const DescText = styled.h5`
  color: #102c57;
  font-family: "Typefez-reg";
  font-size: 16px;
  margin-bottom: 40px;
`

export const ReferralButton = styled(Button)`
  width: 198px;
  height: ${p => p.height || "50px"};
  background-color: #ffd27c;
  color: #166f7b;
  font-family: "Typefez-med";
  font-size: 16;
  outline: none;
  border-color: #ffd27c;
  border-radius: 10px;
  margin-top: 10px;
  -webkit-box-shadow: -1px 7px 5px -4px #a0a0a0;
  box-shadow: -1px 7px 5px -4px #a0a0a0;
  :hover,
  :visited,
  :active,
  :focus {
    background-color: #166f7b !important;
    color: #ffd27c !important;
    outline: none !important;
    box-shadow: none !important;
  }
`
