import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { DotAccordion } from "../HomeComponents/DotAccordion"
import bgGreyRotate from "../../images/logo-big-grey-rotate.svg"
import ReferredImg from "../../images/sg-referral-speaker.svg"
import RefereeImg from "../../images/sg-referral-download.svg"
import ImgSg1A from "../../images/sg-referral-1A.png"
import ImgSg1B from "../../images/sg-referral-1B.png"
import ImgSg1C from "../../images/sg-referral-1C.png"
import ImgSg2A from "../../images/sg-referral-2A.png"
import ImgSg2B from "../../images/sg-referral-2B.png"
import ImgSg2C from "../../images/sg-referral-2C.png"
import ImgSgEN1A from "../../images/sg-referral-en-1A.png"
import ImgSgEN1B from "../../images/sg-referral-en-1B.png"
import ImgSgEN1C from "../../images/sg-referral-en-1C.png"
import ImgSgEN2A from "../../images/sg-referral-en-2A.png"
import ImgSgEN2B from "../../images/sg-referral-en-2B.png"
import ImgSgEN2C from "../../images/sg-referral-en-2C.png"

import ImgIndoReferral1Indo from "../../images/referral-invite-indo-1.png"
import ImgIndoReferral2Indo from "../../images/referral-invite-indo-2.png"
import ImgIndoReferral3Indo from "../../images/referral-invite-indo-3.png"
import ImgIndoReferral1Eng from "../../images/referral-invite-eng-1.png"
import ImgIndoReferral2Eng from "../../images/referral-invite-eng-2.png"
import ImgIndoReferral3Eng from "../../images/referral-invite-eng-3.png"
import ImgIndoReferred1Indo from "../../images/referral-use-indo-1.png"
import ImgIndoReferred2Indo from "../../images/referral-use-indo-2.png"
import ImgIndoReferred3Indo from "../../images/referral-use-indo-3.png"
import ImgIndoReferred1Eng from "../../images/referral-use-eng-1.png"
import ImgIndoReferred2Eng from "../../images/referral-use-eng-2.png"
import ImgIndoReferred3Eng from "../../images/referral-use-eng-3.png"
import { BackgroundImg } from "../HomeComponents/HowToUse"

export const HowToReferral = ({ propsData, isEnglish, isSgReferral }) => {
  const { Left, Right, Title } = propsData

  const data = useStaticQuery(graphql`
    query {
      sg1a: file(relativePath: { eq: "sg-referral-1A.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sg1b: file(relativePath: { eq: "sg-referral-1B.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sg1c: file(relativePath: { eq: "sg-referral-1C.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sg2a: file(relativePath: { eq: "sg-referral-2A.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sg2b: file(relativePath: { eq: "sg-referral-2B.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sg2c: file(relativePath: { eq: "sg-referral-2C.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sgen1a: file(relativePath: { eq: "sg-referral-en-1A.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sgen1b: file(relativePath: { eq: "sg-referral-en-1B.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sgen1c: file(relativePath: { eq: "sg-referral-en-1C.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sgen2a: file(relativePath: { eq: "sg-referral-en-2A.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sgen2b: file(relativePath: { eq: "sg-referral-en-2B.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sgen2c: file(relativePath: { eq: "sg-referral-en-2C.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const Img1A = isEnglish ? data.sgen1a.childImageSharp.fluid : data.sg1a.childImageSharp.fluid
  const Img1B = isEnglish ? data.sgen1b.childImageSharp.fluid : data.sg1b.childImageSharp.fluid
  const Img1C = isEnglish ? data.sgen1c.childImageSharp.fluid : data.sg1c.childImageSharp.fluid
  const Img2A = isEnglish ? data.sgen2a.childImageSharp.fluid : data.sg2a.childImageSharp.fluid
  const Img2B = isEnglish ? data.sgen2b.childImageSharp.fluid : data.sg2b.childImageSharp.fluid
  const Img2C = isEnglish ? data.sgen2c.childImageSharp.fluid : data.sg2c.childImageSharp.fluid

  const [idActiveTop, setIdActiveTop] = useState(0)
  const [defImageTop, setDefImageTop] = useState(Img2A)
  const [idActiveBottom, setIdActiveBottom] = useState(0)
  const [defImageBottom, setDefImageBottom] = useState(Img1A)

  const DataListTop = [
    {
      eventId: "0",
      name: `1. ${Left.Point1}`,
      content: Left.Desc1,
    },
    {
      eventId: "1",
      name: `2. ${Left.Point2}`,
      content: Left.Desc2,
    },
    {
      eventId: "2",
      name: `3. ${Left.Point3}`,
      content: Left.Desc3,
    },
  ]

  const DataListBottom = [
    {
      eventId: "0",
      name: `1. ${Right.Point1}`,
      content: Right.Desc1,
    },
    {
      eventId: "1",
      name: `2. ${Right.Point2}`,
      content: Right.Desc2,
    },
    {
      eventId: "2",
      name: `3. ${Right.Point3}`,
      content: Right.Desc3,
    },
  ]

  const handleActiveTop = id => {
    setIdActiveTop(Number(id))
  }
  const handleActiveBottom = id => {
    setIdActiveBottom(Number(id))
  }

  useEffect(() => {
    if (idActiveTop === 0) {
      setDefImageTop(Img2A)
    } else if (idActiveTop === 1) {
      setDefImageTop(Img2B)
    } else if (idActiveTop === 2) {
      setDefImageTop(Img2C)
    }
  }, [idActiveTop])

  useEffect(() => {
    if (idActiveBottom === 0) {
      setDefImageBottom(Img1A)
    } else if (idActiveBottom === 1) {
      setDefImageBottom(Img1B)
    } else if (idActiveBottom === 2) {
      setDefImageBottom(Img1C)
    }
  }, [idActiveBottom])

  return (
    <>
      <Container>
        <MainTitle
          mbottom="70px"
          fsizeres="42px"
          color="#102C57"
          ffamily="Typefez-black"
        >
          {Title}
          <span style={{ color: "#102C57" }}>?</span>
        </MainTitle>
        <Row className="mb-5">
          <Col md={8} style={{ position: "relative", zIndex: "2" }}>
            <MainDiv pad="50px 10px 10px 40px" margin="0px">
              <MainTitle
                fsize="40px"
                mbottom="40px"
                fsizeres="28px"
                talign="left"
              >
                {Left.Title}
              </MainTitle>
              <DotAccordion
                data={DataListTop}
                isCountries
                sendToParent={handleActiveTop}
                mbottom="30px"
              />
              <BackgroundImg
                src={bgGreyRotate}
                alt="logo"
                top="-300px"
                left="800px"
                width="600px"
              />
            </MainDiv>
          </Col>
          <Col md={4}>
            <PhoneImg fluid={defImageTop} mleft="30px" />
          </Col>
        </Row>

        <Row className="mb-5">
          <Col md={8} style={{ position: "relative", zIndex: "2" }}>
            <MainDiv pad="50px 10px 10px 40px" margin="0px">
              <MainTitle
                fsize="40px"
                mbottom="40px"
                fsizeres="28px"
                talign="left"
              >
                {Right.Title}
              </MainTitle>
              <DotAccordion
                data={DataListBottom}
                isCountries
                sendToParent={handleActiveBottom}
                mbottom="30px"
              />
              <BackgroundImg
                src={bgGreyRotate}
                alt="logo"
                top="150px"
                left="-650px"
                // width="600px"
                rotate="180deg"
              />
            </MainDiv>
          </Col>
          <Col md={4}>
            <PhoneImg fluid={defImageBottom} mleft="30px" />
          </Col>
        </Row>
      </Container>
    </>
  )
}

const MainDiv = styled.div`
  box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  -webkit-box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  -moz-box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  border-radius: 15px;
  /* margin: 15px; */
  padding: ${p => p.pad};
  margin: ${p => p.margin || "50px 0px 10px 0px"};
  position: relative;
  background-color: white;
  @media (max-width: 576px) {
    width: 90%;
  }
`

const MainTitle = styled.h2`
  font-size: ${p => p.fsize || "50px"};
  font-family: ${p => p.ffamily || "Typefez-bold"};
  color: ${p => p.color || "#FF7B5A"};
  text-align: ${p => p.talign || "center"};
  margin-left: ${p => p.mleft};
  margin-bottom: ${p => p.mbottom};
  @media (max-width: 768px) {
    font-size: ${p => p.fsizeres};
  }
`

const PhoneImg = styled(Img)`
  width: 60%;
  height: auto;
  margin-left: ${p => p.mleft};
  margin-right: ${p => p.mright};
  position: relative;
  z-index: 3;
  @media (max-width: 768px) {
    display: none;
  }
`
