import React from "react"
import "../layout"
import { useLocation } from "@reach/router"
import Footer from "../FooterComponents/Footer"
import Seo from "../SEOComponents/Seo"
import { NavigationBar } from "../NavigationBar/parent"
import { HeaderReferral } from "./HeaderReferral"
import { HowToReferral } from "./HowToReferral"
import { TncReferral } from "./TncReferral"
import Download from "../HomeComponents/Download"

// Import Json
import {
  MainPageJson,
  PathJson,
  SgReferralJson,
  ReferralJson,
} from "../location"

function SingaporeReferral() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const SgReferralContent = SgReferralJson()
  const IdReferralContent = ReferralJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const isSgReferral = location.pathname.includes("/sg/referral")

  const MainReferralContent = isSgReferral
    ? SgReferralContent
    : IdReferralContent
  const SeoTitle = isSgReferral ? "sg-referral" : "referral"

  return (
    <div className="maxwidth">
      <Seo title={SeoTitle} />
      <NavigationBar />
      <HeaderReferral
        props={MainPageContent.Header}
        propsLink={PathContent}
        propsData={MainReferralContent.Header}
        isEnglish={isEnglish}
      />
      <HowToReferral
        propsData={MainReferralContent.HowTo}
        isEnglish={isEnglish}
        isSgReferral={isSgReferral}
      />
      <TncReferral
        propsData={MainReferralContent.Tnc}
        isEnglish={isEnglish}
        isSgReferral={isSgReferral}
      />
      <Download propsReferral={MainReferralContent.Download} />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default SingaporeReferral
