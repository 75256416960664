import React from "react"
import { Link } from "gatsby"
import { useStateIfMounted } from "use-state-if-mounted"
import { Row, Col, Container, Button } from "react-bootstrap"
import styled from "styled-components"
import NavigationBar from "../HeaderComponents/NavigationBar"
import HeaderReferralImg from "../../images/header-referral.png"

export const HeaderReferral = ({ props, propsData, propsLink, isEnglish }) => {
  const { Title1, Title2, Title3, Desc1, Desc2, Desc3, Desc4 } = propsData || {}

  return (
    <MainDivHeader style={{ paddingTop: 200 }}>
      <BusinessContainer>
        <BusinessTitle>
          {Title1}
          <BrStyle />
          {Title2}
          {isEnglish && <span style={{ color: "#FF7B5A" }}>!</span>}
          <BrStyle />
          {Title3}
          {!isEnglish && <span style={{ color: "#FF7B5A" }}>!</span>}
        </BusinessTitle>
        <BusinessDesc>
          {Desc1}
          <BrStyle />
          {Desc2}
          {/* <BrStyle />
          {Desc3}
          {Desc4 && (
            <>
              <BrStyle />
              {Desc4}
            </>
          )} */}
        </BusinessDesc>
        {/* <Row>
          <Col lg={6}>
            <BusinessDiv>
              <BusinessTitle>
                {Title1}
                <BrStyle />
                {Title2}
                {isEnglish && <span style={{ color: "#FFD27C" }}>!</span>}
                <BrStyle />
                {Title3}
                {!isEnglish && <span style={{ color: "#FFD27C" }}>!</span>}
                <Border />
              </BusinessTitle>
              <BusinessDesc>
                {Desc1}
                <BrStyle />
                {Desc2}
                <BrStyle />
                {Desc3}
                {Desc4 && (
                  <>
                    <BrStyle />
                    {Desc4}
                  </>
                )}
              </BusinessDesc>
            </BusinessDiv>
          </Col>
          <DivImgAndLegal lg={6}>
            <HeaderImg src={HeaderReferralImg} />
          </DivImgAndLegal>
        </Row> */}
      </BusinessContainer>
    </MainDivHeader>
  )
}

const MainDivHeader = styled.div`
  margin-bottom: 200px;
  @media (max-width: 768px) {
    margin-bottom: 100px;
    min-height: 400px;
  }
`

const BusinessContainer = styled(Container)`
  text-align: center;
  /* padding-left: 100px; */
  @media (max-width: 992px) {
    padding-left: 0px;
  }
`
const BusinessTitle = styled.h2`
  font-family: "Typefez-Black";
  color: #ff7b5a;
  font-size: 72px;
  margin-bottom: 30px;
  @media (max-width: 576px) {
    font-size: 50px;
    text-align: center;
  }
`

const BusinessDesc = styled.p`
  font-family: "Typefez-reg";
  font-size: 18px;
  color: #102C57;
  padding: 0px 15%;
`

const BrStyle = styled.br`
  @media (max-width: 768px) {
    display: none;
  }
`
